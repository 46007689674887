// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconSVG from '@Base/SVG/IconSVG';
import iconLookup from './timelineIcons';
import { TextButton } from '@Base/Buttons';

function doAutoShow(type) {
  switch (type) {
    case 'NOTE_ADDED':
    case 'NOTE_DELETED':
    case 'NOTE_EDITED':
    case 'SMS_SENT':
    case 'SMS_RECEIVED':
    case 'NOTE':
      return true;
    default:
      return false;
  }
}

export function extractEmailInfo(emailString) {
  const subjectRegex = /<span id='subject'>(.*?)<\/span>/;
  const bodyRegex = /<span id='body'>([\s\S]*?)<\/span>$/;

  const subjectMatch = emailString.match(subjectRegex);
  const bodyMatch = emailString.match(bodyRegex);

  const subject = subjectMatch ? subjectMatch[1] : null;
  const body = bodyMatch ? bodyMatch[1] : null;

  return { subject, body };
}

function TimelineEntry({ type, title, subtitle, createdDateTime, content }) {
  const [isContentOpen, setIsContentOpen] = useState(false);

  useEffect(() => {
    setIsContentOpen(doAutoShow(type));
  }, [type]);

  const icon = iconLookup[type];

  let emailSubject = undefined;
  let parsedContent;

  if (type === 'NOTE_ADDED' && content) {
    parsedContent = content.replace(/@\[(.*?)]/g, '<strong>@$1</strong>').replace(/#\[(.*?)]/g, '<strong>#$1</strong>');
  } else {
    parsedContent = content;
  }

  if (type === 'EMAIL_SENT' && content) {
    const emailInfo = extractEmailInfo(parsedContent);
    if (emailInfo.subject) {
      emailSubject = 'Subject: '.concat(emailInfo.subject);
      parsedContent = emailInfo.body;
    }
  }

  return (
    <section className="timeline-entry">
      <div className="tl-icon">{icon && <IconSVG {...icon} width="1.25em" />}</div>
      <div className={`tl-container tw-flex tw-flex-col tw-w-full ${parsedContent ? 'has-content' : ''}`}>
        <div className="tw-flex tw-flex-row">
          <div className="tl-entry-data">
            <time dateTime={createdDateTime}>{createdDateTime}</time>
            <p className="tl-title">{title}</p>
            {subtitle && <p className="tl-subtitle">{subtitle}</p>}
            {emailSubject && <p className="tl-subtitle">{emailSubject}</p>}
          </div>
          {parsedContent && (
            <div className="tl-content">
              <TextButton
                action={() => setIsContentOpen(!isContentOpen)}
                className="btn-outline tl-toggle"
                label={isContentOpen ? 'Hide' : 'View'}
                size="sm"
              />
            </div>
          )}
        </div>
        {parsedContent && (
          <div className="tl-content tw-ml-0" style={{ marginLeft: '0' }}>
            <div
              className={`tw-content ${isContentOpen ? 'is-open' : ''}`}
              dangerouslySetInnerHTML={{ __html: parsedContent }}
            />
          </div>
        )}
      </div>
    </section>
  );
}

TimelineEntry.propTypes = {
  content: PropTypes.string,
  createdDateTime: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

TimelineEntry.defaultProps = {
  content: null,
  createdDateTime: null,
  subtitle: null,
  title: null,
  type: null,
};

export default TimelineEntry;
