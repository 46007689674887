/* eslint-disable max-len */
import baseConfig from '../../src/config/baseConfig';

const config = {
  ...baseConfig,
  version: 2367,
  env: 'dev',
  api: {
    ...baseConfig.api,
    middlewareAPIURL: process.env.MIDDLEWARE_API_URL || 'https://ats-api-dev.talent-funnel.com/ats',
    jsAPIURL: process.env.REACT_APP_MIDDLEWARE_API_URL || 'https://ats-api-dev.talent-funnel.com/js',
  },
  datadog: {
    ...baseConfig.datadog,
    sampleRate: 10,
    sessionReplaySampleRate: 10,
  },
  googleMaps: {
    apiKey: process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyASYdCG9XW6mDZPUeQtHBZtVQBYPmjQdrY',
  },
  ga: {
    measurementId: 'G-GXG0NW14BR',
  },
  web: {
    formsWebUrl: process.env.FORMS_WEB_URL || 'https://apply-dev.talent-funnel.com/form',
    bookingInWebUrl: process.env.BOOKING_IN_WEB_URL || 'https://booking-dot-e4s-development.nw.r.appspot.com/invite',
    bookingInInterviewUrl:
      process.env.BOOKING_IN_WEB_URL || 'https://booking-dot-e4s-development.nw.r.appspot.com/interview',
  },
  auth: {
    ...baseConfig.auth,
    applicationId: process.env.CLIENT_ID || '63f0dbe0-295a-462b-a88a-0cf42477ec94',
    tenantId: process.env.TENANT_ID || '19b1db79-f668-4589-94d0-757079535feb',
  },
  publishing: {
    ...baseConfig.publishing,
    vacancyFeedUrl: process.env.VACANCY_FEED_URL || 'https://feeds-dev.talent-funnel.com',
  },
  forms: {
    ...baseConfig.forms,
    genericStylingForm:
      process.env.QUESTION_SEARCH_PATH ||
      'https://apply-dev.talent-funnel.com/form?id=c70e31fc-40d0-44a9-9d04-fbe7be15ac86&devMode=true',
  },
  joditKey: 'AC6CB-1UKH1-CDOO6-U567X',
};

export default config;
